.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;

    .menu {
        height: calc(100% - 156px);
        scrollbar-width: thin!important;
    
        &::-webkit-scrollbar-thumb {
            transition: all .3s ease;
            visibility: hidden;
        
            @media (max-width: 425px) {
                display: none!important;
            }
        }
    
        &:hover {
            &::-webkit-scrollbar-thumb {
                visibility: visible!important;
            }
        }        
    }

    ul {
        padding: 0;
    }
}