.navparent {
    scrollbar-width: none!important;

    &::before, &::after {

    }

    &::-webkit-scrollbar {
        width: 0px!important;
        height: 0px!important;
        scrollbar-width: thin;
    }    
    &::-webkit-scrollbar-thumb {
        visibility: hidden;
    }
}

.link {
    width: max-content!important;
    color: #757575!important;

    &.nav-tabs {
        border-width: 0 0 3px 0!important;
        border-color: transparent;  

        .active {
            border: none;
            border-bottom-width: 4px!important;
            border-color: blue;
        }
    }
}

