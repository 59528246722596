// Local variables
$breadcrumb-divider: url("../asset/images/divider.svg");

html {
  font-family: $font-family-base !important;
  font-weight: 100;
  font-size: $font-size-initial;
}

body {
  overflow-x: hidden;
  color: $text-color;
  background: $body-bg;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;

}

// Custom Styling
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  scrollbar-width: thin;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #c8baba;
  margin: 0 1px!important;
}
::-webkit-scrollbar-track {
  background: transparent!important;
}


html,
body,
#app {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 0!important;
}

a {
  text-decoration: none!important;
}

a,
.dropdown-item,
.btn {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  font-family: $font-family-base;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

th {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
}

.table td {
  vertical-align: middle;
}

ul ul,
ol ul {
  list-style: none;
  padding: 0;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

#showOnHover {
  &::-webkit-scrollbar-thumb {
    transition: all .3s ease;
    visibility: hidden;

    @media (max-width: 425px) {
      display: none!important;
    }
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible!important;
    }
  }    
}

.breadcrumb {
  li {
    a {
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  
  .breadcrumb-item+.breadcrumb-item {
    &::before {
      margin-top: 1px;
      content:var(--bs-breadcrumb-divider, $breadcrumb-divider) !important;
    }
  }
}

.active-menu {
  transition: all 0.2s ease-in-out;
  
  img {
    filter: invert(41%) sepia(89%) saturate(514%) hue-rotate(180deg) brightness(109%) contrast(99%) !important;
  }
}
