@import "../../styles/helpers";

.root {

  :global {
    .input-group {
      overflow: hidden;
    }

    .input-group-prepend {
      background-color: $white;
      transition: background-color ease-in-out 0.15s;
      padding-right: 1rem;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .form-control {
      background-color: $white;
      height: 45px;
      border: none;
      border-radius: .5rem;
      padding-left: 1rem;
      font-size: $font-size-base;
    }

    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.navbarBlock {
  display: flex;
  color: $icon-color;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 44px;
  width: 44px;
  border-radius: 50%;

  img {
    height: 100%
  }
}

.menuIcon {
  fill: $icon-color;
}

.notificationImage {
  margin: 8px auto 12px;
  width: 100%;
}

.mariaImage {
  margin-right: 12px;
  width: 40px;
  height: 40px;
}

.count {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 7px;
  right: 18px;
  background: $pink;
  border-radius: 50%;
}

.form-control::placeholder {
  color: red;
  opacity: 1;
}

.dropdownProfileItem {
  display: flex;
  &:hover {
    svg {
      path {
        fill: $default;
        stroke: $default;
      }
    }
  }
}

.profile_logo {
  li {
    &::marker {
      display: none;
    }

    a {
      // padding: 0!important;
    }
  }
}

