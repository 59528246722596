.tables {
    table {
        // width: 100%;
        // table-layout: fixed;
        // table-layout: auto;
        // display: block;

        overflow-x: auto;
        white-space: nowrap;
        // white-space: pre-wrap;
                
        thead {
            th {
                border-bottom-width: 0!important;
                padding-left: 0 !important;
                font-size: 0.9rem !important;
                font-weight: 500 !important;
                vertical-align: baseline !important;
            }
        }
        tbody {
            border-top: 1px solid #eee!important;
            
            tr {
                td {
                    font-size: 0.9rem !important;
                    font-weight: 500 !important;
                    padding: 1rem 0.5rem 1rem 0rem;
                    text-transform: capitalize !important;
                    vertical-align: baseline !important;
                }
            }
        }
    }
}

.pagination {
    padding: 0!important;
    margin: 0;

    .page-item {
        width: 2rem!important;
        background-color: transparent!important;
    }
}

.modal-content {
    border-radius: 10px;
}